import { Text } from '@/components/common';
import { styled } from '@mui/material';
export const ErrorMessage = styled('div')(() => ({
  color: '#e54155',
  marginTop: 16,
  fontSize: 13,
  lineHeight: 1.6,
}));

export const ModalHeader = styled(Text)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: '500',
  justifyContent: 'space-between',
  marginBottom: 0,
  [theme.breakpoints.down('sm')]: {
    marginBottom: 16,
  },
}));

export const ErrorMessageWrapper = styled(Text)(({ theme }) => ({
  fontSize: 14,
  lineHeight: '16px',
  paddingLeft: 12,
  color: theme.palette.error.main,
  textUnderlineOffset: '3px',
}));

export const ErrorWrapper = styled(Text)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('sm')]: {
    marginBottom: 0,
    marginTop: 24,
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: 16,
    marginTop: 0,
  },
}));

