import { styled } from '@mui/material';

export const FormWrapper = styled('div', { name: 'FormWrapper' })(({ theme }) => ({
  display: 'flex',
  flex: '1 0',
  height: '100%',
  flexDirection: 'column',
  [theme.breakpoints.up('xl')]: {
  },
  [theme.breakpoints.up('xxl')]: {
  },
}));
