import { Button as BaseButton, ButtonProps as MuiButtonProps, styled } from '@mui/material';
import React from 'react';

const StyledButton = styled(BaseButton, {
  shouldForwardProp: (propName: string) => propName !== 'color',
})<{ color: string }>(({ color }) => ({
  borderRadius: '0px',
  height: '48px',
  fontFamily: 'Satoshi Variable',
  fontSize: 16,
  textTransform: 'none',
  lineHeight: 1.23,
  ...(color === 'dark' && {
    color: '#fff',
    backgroundColor: '#000',

    '&:hover': {
      //you want this to be the same as the backgroundColor above
      backgroundColor: '#000',
    },
    '&:disabled': {
      color: '#fff',
      backgroundColor: '#55555540'
    },
  }),
  ...(color === 'white' && {
    color: '#000',
    backgroundColor: '#fff',
    '&:hover': {
      //you want this to be the same as the backgroundColor above
      backgroundColor: '#fff',
    },
    '&:disabled': {
      backgroundColor: '#555',
      color: '#000',
    },
  }),
}));

type ButtonProps = { color: 'dark' | 'white' } & Omit<MuiButtonProps, 'color'>;

const Button: React.FC<ButtonProps> = ({ children, color, ...props }) => {
  return (
    <StyledButton {...props} color={color}>
      {children}
    </StyledButton>
  );
};

Button.displayName = 'Button';

export default Button;
