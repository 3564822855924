import { Text } from '@/components/common';
import AddNewPaymentModal from '@/components/modal/AddNewPayment';
import PendingKycModal from '@/components/modal/PendingKYCModal';
import RejectedKycModal from '@/components/modal/RejectedKYCModal';
import { useUser } from '@/hooks/useUser';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { CARD_TYPE } from '@/utils/constant';
import { ModalRenderer } from '@/utils/modalUtils';
import { get } from 'lodash';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CardListForCP from './CardListForCP';
import { AddPaymentMethodBtnForCP, ContinueBtnForCP, DepositPaymentWrapper } from './styled';

const DepositPayment: React.FC<any> = () => {
  const { t } = useTranslation();
  const userId = useAppSelector((state: any) => state.auth.userId);
  const cardList = useAppSelector((state: any) => get(state, 'user.userInfo.credit_cards'));
  const cardWithoutApplePay = cardList?.filter((card: any) => card.wallet !== CARD_TYPE.APPLE);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [reloadUser] = useUser(userId);

  useEffect(() => {
    (async () => {
      dispatch({ type: 'SHOW_LOADING' });
      await reloadUser();
      dispatch({ type: 'HIDE_LOADING' });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cardWithoutApplePay?.length > 0) {
      dispatch({
        type: 'UPDATE_STATUS',
        payload: { deposit_passed: true },
      });
    } else {
      dispatch({
        type: 'UPDATE_STATUS',
        payload: { deposit_passed: false },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardWithoutApplePay]);

  const onHandleContinue = async () => {
    dispatch({ type: 'SHOW_LOADING' });
    reloadUser().then((user) => {
      const kycRequired = get(user, ['insurance', 'requires'], []);
      const verification_status = get(user, ['verification_status'], null);
      dispatch({ type: 'HIDE_LOADING' });
      if (
        verification_status === 'manual_verification' ||
        (kycRequired.length === 1 && kycRequired?.includes('User.selfie-in-verification'))
      ) {
        ModalRenderer.open({
          component: PendingKycModal,
          theme: 'white',
          inMobile: 'bottom',
        });
        return;
      }

      if (verification_status === 'rejected') {
        ModalRenderer.open({
          component: RejectedKycModal,
          theme: 'white',
          inMobile: 'bottom',
        });
        return;
      }
      navigate('/thank-you');
    });
  };

  const addPaymentMethod = async () => {
    ModalRenderer.open({
      component: AddNewPaymentModal,
      theme: 'white',
      inMobile: 'bottom',
    });
    return;
  };

  return (
    <>
      <Text color="darkPrimary" variant="title">
        {t('PAYMENT_METHOD')}
      </Text>
      <Text color="darkSecondary" variant="content" size="16">
        <Trans
          i18nKey="DEPOSIT_PAYMENT_SUBTITLE"
          components={{
            link1: (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                href="https://help.theout.com/hc/en-gb/articles/9944136066333-How-much-is-the-deposit-to-secure-my-booking-"
                target="blank"
              />
            ),
          }}
        />
      </Text>
      <DepositPaymentWrapper>
        <CardListForCP cardList={cardWithoutApplePay} />
        {cardWithoutApplePay?.length ? (
          <AddPaymentMethodBtnForCP fullWidth variant="contained" color="white" onClick={onHandleContinue}>
            {t('DEPOSIT_CONTINUE_BTN')}
          </AddPaymentMethodBtnForCP>
        ) : (
          <ContinueBtnForCP fullWidth variant="contained" color="white" type="submit" onClick={addPaymentMethod}>
            {t('ADD_PAYMENT_METHOD')}
          </ContinueBtnForCP>
        )}
      </DepositPaymentWrapper>
    </>
  );
};
export default DepositPayment;
