import { styled } from '@mui/material';
import { isString } from 'lodash';

export const Text = styled('div', {
  shouldForwardProp: (prop: string) => prop !== 'variant' && prop !== 'size' && prop !== 'color' && prop !== 'font',
})<{
  size: string | Record<string, string>;
  color: 'primary' | 'secondary' | 'darkPrimary' | 'darkSecondary';
  variant: 'title' | 'content';
  font: 'Book' | 'Medium' | 'Light';
}>(({ theme, size, color, variant, font }) => ({
  ...(color === 'primary' && {
    color: '#000',
  }),
  ...(color === 'secondary' && {
    color: '#555',
  }),
  ...(color === 'darkPrimary' && {
    color: '#fff',
  }),
  ...(color === 'darkSecondary' && {
    color: '#BBB',
  }),
  ...(variant === 'title' && {
    fontWeight: '500',
    lineHeight: '1.5',
    fontSize: '32px',
    marginBottom: 24,
    fontFamily: 'Satoshi Variable',
    [theme.breakpoints.up('md')]: {
      marginBottom: 32,
      fontSize: 40,
      lineHeight: '1.33',
    },
  }),
  ...(variant === 'titleModal' && {
    fontWeight: '500',
    lineHeight: '1.76',
    fontSize: '16px',
    marginBottom: 24,
    fontFamily: 'Satoshi Variable',
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
      lineHeight: 1.56,
    },
  }),
  ...(variant === 'content' && {
    fontWeight: '500',
    lineHeight: 1.5,
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
      lineHeight: 1.5,
    },
  }),
  ...(font && { fontFamily: `FuturaLTPro-${font}` }),
  ...(size &&
    (isString(size)
      ? { fontSize: `${size}px !important` }
      : {
          [theme.breakpoints.up('xxs')]: {
            fontSize: `${size.xxs}px`,
          },
          [theme.breakpoints.up('xs')]: {
            fontSize: `${size.xs}px`,
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: `${size.sm}px`,
          },
          [theme.breakpoints.up('md')]: {
            fontSize: `${size.md}px`,
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: `${size.lg}px`,
          },
          [theme.breakpoints.up('xl')]: {
            fontSize: `${size.xl}px`,
          },
          [theme.breakpoints.up('xxl')]: {
            fontSize: `${size.xxl}px`,
          },
          [theme.breakpoints.up('xxxl')]: {
            fontSize: `${size.xxxl}px`,
          },
        })),
}));
