import { updateUser } from '@/api/user';
import { Button, Text } from '@/components/common';
import { sendVerificationCode, verificationCodeAndUpdatePhone, verificationPhoneCode } from '@/libs/firebase';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import authReducer from '@/redux/reducers/authReducer';
import { ModalHeader } from '@/styles/common';
import { ERROR_CODES } from '@/utils/constant';
import { endUrlForStep } from '@/utils/helper';
import { ModalRenderer } from '@/utils/modalUtils';
import * as FS from '@fullstory/browser';
import { get } from 'lodash';
import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSidebar } from '../../../hook/useSidebar';
import { ErrorMessage, VerificationInputStyled } from './styled';

const VerificationPhoneCodeModal: React.FC<any> = ({ phoneNumber, isVerified, closeModal }) => {
  const { t } = useTranslation();
  const [verifyCode, setVerificationCode] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const userId = useAppSelector((state: any) => get(state, 'user.userInfo.id'));
  const sidebar = useAppSelector((state: any) => state.sidebar);
  const userType = useAppSelector((state: any) => state.user.userType);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const verificationRef = useRef(null);
  const [reloadStep] = useSidebar(userId);

  const onChange = async (e: any) => {
    setErrorMessage('');
    setVerificationCode(e);
  };

  // const clearRecapchaContent = () => {
  //   if (reCapchaContainerElement.current) {
  //     reCapchaContainerElement.current.innerHTML = '<div id="recapcha-container"></div>';
  //   }
  // };

  const onGetStep = (listStepServer: any): number => {
    const { currentStep, ...listStep } = sidebar;
    const newData = { ...listStep, ...listStepServer };
    let nextStep = 0;
    Object.keys(newData)
      .filter((_, index) => index > currentStep)
      .every((item2) => {
        if (!newData[item2]) {
          nextStep = Object.keys(newData).indexOf(item2);
          return false;
        } else return true;
      });
    return nextStep;
  };

  const onHandleConfirmCode = async () => {
    try {
      if (verifyCode) {
        dispatch({ type: 'SHOW_LOADING' });

        if (window?.verificationId) {
          await verificationCodeAndUpdatePhone(verifyCode);
        }
        if (window?.confirmationResult) {
          await verificationPhoneCode(verifyCode);
        }
        // set user id to global state
        dispatch(authReducer.actions.signUpSuccess(userId));

        ModalRenderer.close('all');
        FS.identify(userId);
        if (isVerified) {
          dispatch({ type: 'SHOW_LOADING' });
          dispatch({
            type: 'UPDATE_STATUS',
            payload: { create_account_passed: true },
          });
          const kycStep = await reloadStep(['create_account_passed']);
          dispatch({ type: 'NEXT_STEP_AUTO' });
          const step = onGetStep(kycStep);
          navigate(endUrlForStep(userType, step));
        } else {
          dispatch({ type: 'SHOW_LOADING' });
          await updateUser(userId, { phone_number: phoneNumber });
          dispatch({
            type: 'UPDATE_STATUS',
            payload: { create_account_passed: true },
          });
          const kycStep = await reloadStep();
          dispatch({ type: 'NEXT_STEP_AUTO' });
          const step = onGetStep(kycStep);
          navigate(endUrlForStep(userType, step));
        }
      }
    } catch (error) {
      dispatch({ type: 'HIDE_LOADING' });
      switch (error) {
        case ERROR_CODES.CODE_EXPIRED:
          setErrorMessage(t('ERROR_MESSAGE_CODE_EXPIRED'));
          return;
        case ERROR_CODES.INVALID_CODE:
          setErrorMessage(t('ERROR_MESSAGE_INVALID_CODE'));
          return;
        default:
          setErrorMessage(t('ERROR_MESSAGE_SOMETHING_WENT_WRONG'));
          break;
      }
    }
  };

  const sendAgain = async () => {
    try {
      // clearRecapchaContent();
      setErrorMessage('');
      setVerificationCode('');
      (verificationRef?.current as any)?.__clearvalues__();
      dispatch({ type: 'SHOW_LOADING' });
      await sendVerificationCode(phoneNumber);
    } catch (error) {
      if (error === ERROR_CODES.INVALID_PHONE_NUMBER || error === ERROR_CODES.EXISTING_PHONE_NUMBER) {
        setErrorMessage(t('ERROR_MESSAGE_INVALID_PHONE_NUMBER'));
        return;
      }
      if (error === ERROR_CODES.TOO_MANY_REQUESTS || error === ERROR_CODES.TOO_MANY_REQUEST_CODE) {
        setErrorMessage(t('ERROR_MESSAGE_TOO_MANY_REQUESTS'));
        return;
      }
      if (error === ERROR_CODES.TOO_MANY_ATTEMPS_CODE) {
        setErrorMessage(t('ERROR_MESSAGE_TOO_MANY_ATTEMPS_CODE'));
        return;
      }
      setErrorMessage(t('ERROR_MESSAGE_SOMETHING_WENT_WRONG'));
    } finally {
      dispatch({ type: 'HIDE_LOADING' });
    }
  };
  const onClose = () => {
    closeModal();
  };
  const isDisableBtn = !!errorMessage || verifyCode?.length !== 6;

  return (
    <>
      <ModalHeader color="primary" variant="title" size="21" sx={{ lineHeight: '28px' }}>
        {t('VERIFICATION_CODE')}
        <img
          style={{ width: '20px', height: '20px', cursor: 'pointer' }}
          src="/images/icon/ico_close_x.svg"
          onClick={onClose}
          alt=""
        />
      </ModalHeader>
      <Text color="secondary" variant="content" size="16" style={{ marginBottom: '24px' }}>
        <Trans
          i18nKey="VERIFICATION_CODE_CONTENT"
          values={{ digit: phoneNumber?.slice(-3) }}
          components={{
            digit: <span className="fs-mask" />,
          }}
        />
      </Text>
      <VerificationInputStyled
        autoFocus
        fields={6}
        onChange={(e: any) => onChange(e)}
        type={'number'}
        className="verif-code"
        placeholder={['0', '0', '0', '0', '0', '0']}
        ref={verificationRef}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <Button
        sx={{ marginTop: '24px', textTransform: 'none' }}
        fullWidth
        variant="contained"
        color="dark"
        onClick={onHandleConfirmCode}
        disabled={isDisableBtn}
      >
        {t('CONFIRM_CODE')}
      </Button>
      <Text style={{ marginTop: '24px' }} variant="content" color="primary" size="15">
        <Trans
          i18nKey="NOT_RECEIVE_SEND_CODE_AGAIN"
          components={{
            link1: <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => sendAgain()} />,
          }}
        />
      </Text>
    </>
  );
};

export default VerificationPhoneCodeModal;
