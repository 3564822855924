import { addCreditCard, getClientSecretStripe } from '@/api/payment';
import { Button, StyledForm } from '@/components/common';
import { FormMarginDense } from '@/components/form';
import PaymentForm from '@/components/page/DepositPayment/PaymentForm';
import StripeInputLight from '@/libs/stripe/StripeInput/StripeInputLight';
import { useUser } from '@/hooks/useUser';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { ModalRenderer } from '@/utils/modalUtils';
import { cardNameSchema, PostcodeSchema } from '@/utils/validation';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Formik } from 'formik';
import React, { useRef, useState } from 'react';
import * as yup from 'yup';
import { ModalHeader, ErrorMessageWrapper, ErrorWrapper } from './styled';
import { Trans, useTranslation } from 'react-i18next';
import { notify } from '@/components/common/Toast';
import { getAddCardErrorCotent, getStripeErrorCotent } from '@/utils/cards';
import { CONTACT_EMAIL } from '@/utils/constant';

const schema = yup.object().shape({
  cardName: cardNameSchema,
  postCode: PostcodeSchema,
});

const AddNewPaymentModal: React.FC<any> = ({ closeModal }) => {
  const userId = useAppSelector((state: any) => state.auth.userId);
  const stripe = useStripe();
  const elements = useElements();
  const [reloadUser] = useUser(userId);
  const formikRef = useRef(null);
  const dispatch = useAppDispatch();
  const [isCompleted, setCompleted] = useState(false);
  const { t } = useTranslation();
  const [error, setError] = useState<string>('');

  const onClose = () => {
    closeModal();
  };

  const onSubmit = async (values: any) => {
    setError('');
    dispatch({ type: 'SHOW_LOADING' });
    if (!stripe || !elements) {
      return;
    }
    try {
      const { client_secret } = await getClientSecretStripe(userId);
      const cardTokenInfo = await stripe.createToken(elements.getElement(CardNumberElement)!);
      if (!cardTokenInfo.error) {
        const resultConfirmCardSetup = await stripe.confirmCardSetup(client_secret, {
          payment_method: {
            card: elements.getElement(CardNumberElement)!,
            billing_details: {
              name: values.cardName,
              address: {
                postal_code: values.postCode,
              },
            },
          },
        });
        if (!resultConfirmCardSetup.error) {
          const bodyAddCard = {
            first_name: values.cardName,
            last_name: '',
            token: resultConfirmCardSetup.setupIntent.payment_method,
          };
          await addCreditCard(userId, bodyAddCard);
          notify(t('PAYMENT_METHOD_SAVED'));
          await reloadUser();
          ModalRenderer.close('all');
        } else {
          setError(getStripeErrorCotent(resultConfirmCardSetup?.error?.code || ''))
        }
      } else {
        setError(getStripeErrorCotent(cardTokenInfo?.error?.code || ''))
      }
    } catch (err) {
      setError(getAddCardErrorCotent(err || ''))
    } finally {
      dispatch({ type: 'HIDE_LOADING' });
    }
  };

  return (
    <>
      <ModalHeader color="primary" variant="title" size="18" sx={{ lineHeight: '28px' }}>
        {t('ADD_PAYMENT_METHOD')}
        <img
          style={{ width: '20px', height: '20px', cursor: 'pointer' }}
          src="/images/icon/ico_close_x.svg"
          onClick={onClose}
          alt=""
        />
      </ModalHeader>
      {error ? <>
        <ErrorWrapper>
          <img src={`/images/icon/Icon_Warning.svg`} alt="" width={16} height={16} />
          <ErrorMessageWrapper>
            <Trans
              i18nKey={error}
              components={{
                strong: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a href={CONTACT_EMAIL} />
                ),
              }}
            />
          </ErrorMessageWrapper>
        </ErrorWrapper>
      </> : null}
      <Formik
        initialValues={{
          cardName: '',
          postCode: '',
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {(props) => (
          <StyledForm>
            <PaymentForm StripeInput={StripeInputLight} themeColor='white' setCompleted={setCompleted} marginRight={24} mode='modal' />
            <FormMarginDense>
              <Button
                fullWidth
                variant="contained"
                color="dark"
                type="submit"
                disabled={!(!Boolean(props.errors.cardName) && !Boolean(props.errors.postCode) && isCompleted)}
                sx={{
                  boxShadow: 'unset', '&:hover': {
                    boxShadow: 'unset'
                  }
                }}
              >
                {t('SAVE_PAYMENT_METHOD')}
              </Button>
            </FormMarginDense>
          </StyledForm>
        )}
      </Formik>
    </>
  );
};

export default AddNewPaymentModal;
