import { styled, TextField as MuiTextField } from '@mui/material';
import { useField } from 'formik';
import React from 'react';
import { ErrorMessage } from '../ErrorMessage';
import FormLabel from '../Label';

const TextFieldWrapper = styled('div')(({ theme }) => ({
  display: 'block',
  width: '100%',
}));

export const StyledTextField = styled(MuiTextField, {
  shouldForwardProp: (propName: string) => propName !== 'themeColor' && propName !== 'size',
})<{ themeColor: string }>(({ theme, themeColor = 'dark', size, variant }) =>
  variant === 'outlined'
    ? {
        input: {
          color: themeColor === 'dark' ? '#fff' : '#000',
          WebkitBoxShadow: `0 0 0 1000px ${themeColor === 'dark' ? 'black' : 'white'} inset`,
          WebkitTextFillColor: themeColor === 'dark' ? '#fff' : '#000',
        },
        '& label': {
          color: '#999',
          fontWeight: 500,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #262626',
          borderRadius: '0px',
          transition:
            'border-color 200ms cubic-bezier(0.0,0,0.2,1) 0ms, transform 200ms cubic-bezier(0.0,0,0.2,1) 0ms, max-width 200ms cubic-bezier(0.0,0,0.2,1) 0ms',
        },
        '&:hover, & .Mui-focused': {
          '& label': {
            color: '#fff',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #fff',
          },
        },
      }
    : {
        '& input:-webkit-autofill:valid': {
          '-webkit-box-shadow': '0 0 0 1000px #000 inset !important',
          '-webkit-text-fill-color': themeColor === 'dark' ? '#fff !important' : '#000 !important',
          '-webkit-transition-delay': '99999s',
          'caret-color': themeColor === 'dark' ? '#fff' : '#000',
        },
        input: {
          color: themeColor === 'dark' ? '#fff' : '#000',
          fontSize: size ?? '15px',
          lineHeight: 1.07,
          height: '16px',
          WebkitBoxShadow: `0 0 0 1000px ${themeColor === 'dark' ? 'black' : 'white'} inset`,
          WebkitTextFillColor: themeColor === 'dark' ? '#fff' : '#000',
          [theme.breakpoints.up('sm')]: {
            fontSize: 16,
            lineHeight: 1,
            paddingTop: 5,
          },
          [theme.breakpoints.down('sm')]: {
            paddingTop: 5,
          },
        },
        '& label': {
          color: '#999',
        },
        '& .StripeElement': {
          padding: '1px 0 !important',
        },
        '& .MuiInput-underline': {
          padding: '11px 0',
        },
        '& .MuiInput-underline:after': {
          borderBottom: '1px solid',
          borderBottomColor: themeColor === 'dark' ? '#fff' : '#000',
          left: '0',
          transformOrigin: 'left center',
        },
        '& .Mui-error:after': {
          borderBottomColor: '#e54155',
        },
        '& .MuiFormHelperText-root.Mui-error': {
          marginTop: 16,
          color: '#e54155',
          fontSize: 13,
          lineHeight: 1.6,
        },

        '& .MuiInput-underline:before': {
          borderBottomColor: themeColor === 'dark' ? '#272727' : '#dedede',
        },
        '& .MuiInput-underline:hover:before': {
          borderBottomColor: themeColor === 'dark' ? '#272727' : '#dedede',
          borderBottom: `1px solid ${themeColor === 'dark' ? '#272727' : '#dedede'}`,
        },
      },
);

const TextField = ({ config, name, formik }: any) => {
  const [field, meta] = useField(name ?? 'TextField');
  const [focus, setFocus] = React.useState<boolean>(false);
  const {
    label,
    disabled,
    autoComplete = 'false',
    placeholder,
    variant = 'standard',
    margin,
    fullWidth = true,
    type = 'text',
    rows,
    size,
    required,
    autoFocus,
    readOnly,
    maxLength,
    inputComponent,
    endAdornment,
    themeColor = 'dark',
    toUpperCase,
    onClick,
    InputProps,
  } = config;

  // fix: A component is changing an uncontrolled input
  if (!field.value) {
    field.value = config.defaultValue ?? '';
  }
  if (toUpperCase) {
    field.value = field.value.toUpperCase();
  }
  const haveError: boolean = meta.touched && Boolean(meta.error);
  const onBlur = (e: any) => {
    field.onBlur(e);
    setFocus(false);
  };
  const onClickFocus = (e: any) => {
    onClick && onClick();
    setFocus(true);
  };
  const props = {
    ...field,
    onBlur,
    onClick: onClickFocus,
    disabled: disabled,
    required,
    autoFocus,
    variant,
    size,
    inputProps: {
      maxLength: maxLength,
      autoComplete,
      readOnly,
    },
    label: variant === 'outlined' ? label : null,
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      ...InputProps,
      inputComponent,
      endAdornment,
    },
    rows,
    placeholder,
    margin,
    error: haveError && !focus,
    fullWidth,
    type,
    themeColor,
  };
  return (
    <TextFieldWrapper>
      {variant === 'outlined' ? null : <FormLabel themeColor={themeColor} text={label} />}
      <StyledTextField {...props} />
      {!focus && meta.error && meta.touched ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
    </TextFieldWrapper>
  );
};

export default TextField;
