import { styled } from '@mui/system';
import { useField } from 'formik';
import React from 'react';
import { ErrorMessage } from '../ErrorMessage';
import { StyledTextField } from '../TextField';
import CountryCodeSelect from './CountryCodeSelect';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
}));

const PhoneField = ({ config, name, onHandlePhoneCode, PhoneCode, formik }: any) => {
  const [field, meta, { setValue }] = useField(name ?? 'TextField');
  const [focus, setFocus] = React.useState<boolean>(false);

  const onChangePhone = (event: any) => {
    setValue(event.target.value.replace(/[^0-9]/g, ''));
    return;
  };

  const {
    serverError,
    label,
    disabled,
    autoComplete = 'false',
    placeholder,
    variant = 'outlined',
    margin,
    fullWidth = true,
    type = 'text',
    rows,
    size,
    required,
    autoFocus,
    readOnly,
    maxLength,
    InputProps,
  } = config;

  // fix: A component is changing an uncontrolled input
  if (!field.value) {
    field.value = config.defaultValue ?? '';
  }

  const haveError: boolean = (meta.touched && Boolean(meta.error)) || !!serverError;
  const onBlur = (e: any) => {
    field.onBlur(e);
    setFocus(false);
  };
  const onClick = (e: any) => {
    setFocus(true);
  };
  const props = {
    ...field,
    onBlur,
    onClick,
    disabled: disabled,
    required,
    autoFocus,
    variant,
    label,
    size,
    inputProps: {
      maxLength,
      autoComplete,
      readOnly,
      type: 'number',
      inputMode: 'numeric',
      pattern: '[0-9]*',
    },
    InputLabelProps: {
      shrink: true,
    },
    rows,
    placeholder,
    margin,
    error: haveError && !focus,
    fullWidth,
    type,
    InputProps: {
      startAdornment: (
        <CountryCodeSelect defaultValue={PhoneCode} onChange={onHandlePhoneCode} />
      ),
      ...InputProps,
    },
  };

  return (
    <>
      <Wrapper>
        <div style={{ width: '100%' }}>
          <StyledTextField
            {...props}
            name={name}
            onChange={onChangePhone}
            onKeyDown={(evt: any) => (evt.key === '.' || evt.key === 'e') && evt.preventDefault()}
          />
          {!focus && meta.error && meta.touched ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
        </div>
      </Wrapper>
    </>
  );
};

export default PhoneField;
