import { LoadingPage, Sidebar } from '@/components/common';
import { useAppSelector } from '@/redux/hooks';
import { getAccessToken } from '@/utils/auth';
import { UserType, VERIFY_PHONE_PATH } from '@/utils/constant';
import { endUrlForStep } from '@/utils/helper';
import { styled } from '@mui/material';
import { get } from 'lodash';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { LIST_VERIFICATION_ROUTE } from '../constant';
import { useSidebar } from '../hook/useSidebar';

export const Wrapper = styled('div')(({ theme }) => ({
  minWidth: '100vw',
  minHeight: '100vh',
  backgroundColor: '#000',
  display: 'flex',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}));

export const SidebarContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#000',
  width: '100%',
  position: 'relative',
}));

export const Content = styled('div', { name: 'Content' })(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flex: '1 0',
  padding: '30px 24px',
  maxWidth: '768px',
  margin: '0px',
  backgroundColor: '#000',
  color: '#999',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    margin: '0 64px',
    padding: '64px 0px',
  },
  [theme.breakpoints.up('lg')]: {
    margin: '0 auto',
    padding: '64px 0px 64px 0px',
  },
  [theme.breakpoints.up('xl')]: {
    padding: '64px 0px 64px 0px',
  },
  [theme.breakpoints.up('xxl')]: {
    padding: '96px 0px 96px 0px',
  },
}));

export const KYCLayout = () => {
  const userId = useAppSelector((state: any) => get(state, 'user.userInfo.id'));
  const isLoading = useAppSelector((state: any) => state.loading);
  const currentStep = useAppSelector((state: any) => state.sidebar.currentStep);
  const userType = useAppSelector((state: any) => state.user.userType);
  const navigate = useNavigate();
  const location = useLocation();
  const [reloadStep] = useSidebar(userId);
  const isAddPaymentMethodForCP = useAppSelector((state: any) => state.user.isAddPaymentMethod);

  useEffect(() => {
    if (userType == null) {
      navigate('/404');
      return;
    }

    if ((!userId && location.pathname !== VERIFY_PHONE_PATH) || !getAccessToken()) {
      navigate('/register');
      return;
    }

    if (currentStep === 1 && LIST_VERIFICATION_ROUTE.includes(location.pathname)) {
      return;
    }
    if (userType === UserType.CONCIERGE_PORTAL && currentStep <= 1) {
      navigate(endUrlForStep(userType, 1, isAddPaymentMethodForCP));
    } else {
      navigate(endUrlForStep(userType, currentStep, isAddPaymentMethodForCP));
    }
  }, [currentStep, location.pathname, navigate, userId, userType, isAddPaymentMethodForCP]);

  useEffect(() => {
    if (getAccessToken() && reloadStep) {
      reloadStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  return (
    <Wrapper className="fs-unmask">
      <Helmet>
        <meta name="theme-color" content="#000" />
        <style>{'body { background-color: #000 !important; }'}</style>
      </Helmet>
      {isLoading ? <LoadingPage /> : null}
      <SidebarContainer>
        <Sidebar>
          <Content>
            <Outlet />
          </Content>
        </Sidebar>
      </SidebarContainer>
    </Wrapper>
  );
};
