import { getApp, getApps, initializeApp } from 'firebase/app';
import { AppCheck, getToken } from 'firebase/app-check';
import {
  EmailAuthProvider,
  PhoneAuthProvider,
  RecaptchaVerifier,
  getAuth,
  signInWithCredential,
  signInWithCustomToken,
  signInWithPhoneNumber,
  signOut,
  updatePhoneNumber,
} from 'firebase/auth';
import { firebaseConfig } from './env';

export {};

declare global {
  interface Window {
    recaptchaVerifier: any;
    verificationId: any;
    recaptchaWidgetId: any;
    confirmationResult: any;
  }
}

// initial firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const auth = getAuth(app);

//app check
let AppCheckInstance: AppCheck | null = null;
if (typeof window !== 'undefined' && !AppCheckInstance) {
  import('firebase/app-check').then(async (firebaseAppCheck) => {
    const appCheckKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY_APP_CHECK || '';
    AppCheckInstance = firebaseAppCheck.initializeAppCheck(app, {
      provider: new firebaseAppCheck.ReCaptchaV3Provider(appCheckKey),
      isTokenAutoRefreshEnabled: true,
    });
  });
}
export const getAppCheckToken = async () => {
  try {
    if (typeof window !== 'undefined' && AppCheckInstance) {
      const appCheckTokenResponse = await getToken(AppCheckInstance, false);

      const token = appCheckTokenResponse?.token;
      return token;
    }
    return null;
  } catch (error) {
    throw error;
  }
};

export const sendVerificationCode = async (phoneNumber: string) => {
  try {
    auth.useDeviceLanguage();
    if (window?.recaptchaVerifier) {
      const widgetId = await window.recaptchaVerifier.render();
      window.recaptchaWidgetId = widgetId;
    }
    // clear window variable
    if (window?.confirmationResult) {
      delete window.confirmationResult;
    }
    if (window?.verificationId) {
      delete window.verificationId;
    }
    if (!window?.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recapcha-container', {
        size: 'invisible',
      });
    }
    if (window.recaptchaVerifier) {
      const user = auth?.currentUser;
      if (user?.phoneNumber) {
        window.confirmationResult = await signInWithPhoneNumber(auth, `${phoneNumber}`, window.recaptchaVerifier);
        return window.confirmationResult;
      }
      const provider = new PhoneAuthProvider(auth);
      window.verificationId = await provider.verifyPhoneNumber(`${phoneNumber}`, window.recaptchaVerifier);
    }
    return window.verificationId;
  } catch (error: any) {
    throw error?.code;
  }
};

export const verificationCodeAndUpdatePhone = async (code: string) => {
  try {
    if (auth?.currentUser) {
      const phoneCredential = PhoneAuthProvider.credential(window.verificationId, code);
      await updatePhoneNumber(auth.currentUser, phoneCredential);
      delete window.verificationId;
    }
  } catch (error: any) {
    throw error.code;
  }
};

export const verificationPhoneCode = async (code: string) => {
  try {
    if (window?.confirmationResult) {
      await window?.confirmationResult.confirm(code);
      delete window.confirmationResult;
    }
  } catch (error: any) {
    throw error.code;
  }
};

export const firebaseLogin = async (email: string, password: string) => {
  try {
    const emailCredential = EmailAuthProvider.credential(email, password);
    const data = await signInWithCredential(auth, emailCredential);
    if (data) {
      return {
        userId: data.user?.uid,
        accessToken: await data.user?.getIdToken(),
        refreshToken: data.user?.refreshToken,
      };
    }
  } catch (error) {
    throw error;
  }
};

export const signInWithEmailLink = async (email: string, link: string) => {
  try {
    const credential = EmailAuthProvider.credentialWithLink(email, link);
    const data = await signInWithCredential(auth, credential);
    if (data) {
      return {
        userId: data.user?.uid,
        accessToken: await data.user?.getIdToken(),
        refreshToken: data.user?.refreshToken,
      };
    }
  } catch (error) {
    throw error;
  }
};

export const signInWithCustomTokens = async (token: string) => {
  try {
    const data = await signInWithCustomToken(auth, token);
    if (data) {
      return {
        userId: data.user?.uid,
        accessToken: await data.user?.getIdToken(),
        refreshToken: data.user?.refreshToken,
        phoneNumber: data.user?.phoneNumber,
      };
    }
  } catch (error) {
    throw error;
  }
};

export const getUserFirebase = () => {
  try {
    return auth?.currentUser;
  } catch (error) {
    throw error;
  }
};

export const signOutUser = async () => {
  try {
    if (auth?.currentUser) {
      await signOut(auth);
    }
  } catch (error) {
    throw error;
  }
};
