import { useAppSelector } from '@/redux/hooks';
import { UserType } from '@/utils/constant';
import { getPaymentMethodLabelForSideBar } from '@/utils/helper';
import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../Logo';
import { Text } from '../Text';
import { ACTIVE, NOT_ACTIVE, VERIFIED } from './constant';
import { Footer, StyledLinearProgress, Wrapper } from './styles';

const Sidebar: React.FunctionComponent<any> = ({ children }: any) => {
  const { t } = useTranslation();
  const stepStatus = useAppSelector((state: any) => state.sidebar);
  const currentStep = useAppSelector((state: any) => state.sidebar.currentStep);
  const userType = useAppSelector((state: any) => state.user.userType);
  const isAddPaymentMethodForCP = useAppSelector((state: any) => state.user.isAddPaymentMethod);

  const checkStatus = (index: number, stepName: string) => {
    if (currentStep === index) return ACTIVE;
    return currentStep > index ? VERIFIED : NOT_ACTIVE;
  };

  const checkDepositStatus = (index: number) => {
    if (!stepStatus['deposit_passed'] && currentStep === index) return ACTIVE;
    return stepStatus['deposit_passed'] ? VERIFIED : NOT_ACTIVE;
  };

  const STEP = [
    {
      status: checkStatus(0, 'create_account_passed'),
      content: userType !== UserType.CONCIERGE_PORTAL ? t('CREATE_AN_ACCOUNT') : null,
    },
    {
      status: checkStatus(1, 'verify_identity_passed'),
      content: t('VERIFY_YOUR_IDENTITY'),
    },
    {
      status: checkStatus(2, 'insurance_declaration_passed'),
      content: t('INSURANCE_DECLARATION'),
    },
    {
      status: checkDepositStatus(3),
      content: t(getPaymentMethodLabelForSideBar(userType, isAddPaymentMethodForCP)),
    },
  ].filter((item) => !!item.content);

  const progressBar = useMemo(() => {
    const newCurrentStep = userType === UserType.CONCIERGE_PORTAL ? currentStep : currentStep + 1;
    return (newCurrentStep / STEP.length) * 100;
  }, [currentStep, STEP.length, userType]);

  return (
    <>
      <Wrapper>
        <Logo theme="light" />
        <StyledLinearProgress variant="determinate" value={progressBar} />
      </Wrapper>
      {children}
      <Footer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { md: 'row', xxs: 'column' },
          }}
        >
          <Box sx={{ display: 'flex', gap: { md: 3, xxs: 1 }, flexDirection: { md: 'row', xxs: 'column' } }}>
            <Text color="darkSecondary" variant="content">
              FAQs
            </Text>
            <Text color="darkSecondary" variant="content">
              Contact
            </Text>
            <Text color="darkSecondary" variant="content">
              Privacy Policy
            </Text>
            <Text color="darkSecondary" variant="content">
              Terms & Conditions
            </Text>
          </Box>
          <Box sx={{ display: 'flex', gap: 2, mt: { md: 0, xxs: 3 } }}>
            <img src="/images/cards/Mastercard-white.svg" width={32} height={20} alt="card" />
            <img src="/images/cards/Card_Amex.svg" width={32} height={20} alt="card" />
            <img src="/images/cards/Visa-blue.svg" width={32} height={20} alt="card" />
            <img src="/images/cards/apple-pay.svg" width={32} height={20} alt="card" />
          </Box>
        </Box>
        <Box sx={{ color: '#707274', fontSize: '14px', mt: { md: 2, xxs: 3 } }}>{t('FOOTER_CONTENT')}</Box>
      </Footer>
    </>
  );
};
export default Sidebar;
