import { styled } from '@mui/material';

export const MessageWrapper = styled('div')(({ theme }) => ({
  paddingTop: 16,
  textAlign: 'center',
  fontSize: '16px',
  color: '#BBB',
  fontWeight: 'normal',
  lineHeight: 1.5,
  [theme.breakpoints.up('sm')]: {
    paddingRight: 200,
    paddingLeft: 200,
  },
  [theme.breakpoints.down('sm')]: {
    paddingRight: 40,
    paddingLeft: 40,
  },
}));

export const NoCardWrapper = styled('div')(({ theme }) => ({
  backgroundColor: '#0f0f0f',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '64px 64px',
  margin: '64px 0px',
  [theme.breakpoints.down('lg')]: {
    padding: '64px 0px',
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: '80px',
  },
}));
