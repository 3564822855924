import FormLabel from '../Label';
import { StyledTextField } from '../TextField';
import { TextFieldWrapper } from './styled';

const StripeTextField = ({ label, ...other }: any) => {
  return (
    <TextFieldWrapper>
      <FormLabel themeColor="white" text={label} />
      <StyledTextField {...other} />
    </TextFieldWrapper>
  );
};

export default StripeTextField;
