import { omit } from 'lodash';

const initialState: Record<string, any> = {
  create_account_passed: null,
  verify_identity_passed: null,
  deposit_passed: null,
  currentStep: 0,
};

export const sidebarReducer = (state = initialState, action: Record<any, any>) => {
  const { type, payload } = action;
  switch (type) {
    case 'UPDATE_STATUS': {
      return { ...state, ...payload };
    }
    case 'NEXT_STEP': {
      return { ...state, currentStep: state.currentStep + 1 };
    }
    case 'NEXT_STEP_AUTO': {
      const { currentStep, ...listStep } = state;
      let nextStep;
      Object.keys(listStep)
        .filter((_, index) => index > currentStep)
        .every((item2) => {
          if (!listStep[item2]) {
            nextStep = Object.keys(listStep).indexOf(item2);
            return false;
          } else return true;
        });
      return { ...state, currentStep: nextStep };
    }
    case 'NEXT_MULTI_STEP': {
      return { ...state, currentStep: state.currentStep + payload.stepNum };
    }
    case 'RESET_STEP': {
      return initialState;
    }
    case 'RELOAD_STEP': {
      const stepUpdate = omit(payload.data, payload.exceptStep);
      return { ...state, ...stepUpdate };
    }
    default:
      return state;
  }
};
