import { authHttpClient, httpClient, internalAuthHttpClient } from '@/libs/axios';
import { kyc3Axios } from '@/libs/axios/kyc3Client';
import { getAccessToken } from '@/utils/auth';

interface CustomerInfoRes {
  user_id: string;
  last_name: string;
  first_name: string;
}

interface CustomerInfoCP {
  custom_token: string;
  first_name: string;
  email: string;
  partner_code: string;
}

interface CustomerInfoDetailCP {
  customer_id: string;
  last_name: string;
  first_name: string;
  phone_number: string;
  phone_number_status: string;
  email: string;
}

export const getCusNameByOrderId = async (orderId: string | undefined): Promise<CustomerInfoRes | undefined> => {
  try {
    const response = await internalAuthHttpClient.get(`/order/${orderId}/user`);
    return await response.data.data;
  } catch (error) {
    window.location.href = '/404';
  }
};

export const updateUser = async (uid: string | undefined, payload: any): Promise<any> => {
  try {
    const response = await authHttpClient.patch(`users/${uid}`, payload);
    const data = await response.data;
    return data;
  } catch (error: any) {
    throw error.error;
    //define logging
  }
};

export const reloadUser = async (uid: string | undefined): Promise<any> => {
  try {
    const response = await authHttpClient.get(`users/${uid}`);
    const data = await response.data;
    return data;
  } catch (error: any) {
    throw error.error;
    //define logging
  }
};

export const getStep = async (uid: string | undefined): Promise<any> => {
  try {
    const response = await authHttpClient.get(`users/${uid}`, { params: { progress_bar: 'True' } });
    const data = await response.data;
    return data;
  } catch (error: any) {
    throw error.error;
    //define logging
  }
};

export const updateUserSelfie = async (
  uid: string | undefined,
  variant: string | undefined,
  id: string | undefined,
): Promise<any> => {
  try {
    const formData = {
      id,
    };
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth-Token': getAccessToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    };
    const url = `${process.env.REACT_APP_API_URL}/users/${uid}/documents/selfie${variant === 'video' ? '/video' : ''}`;
    //@ts-ignore
    const response = await fetch(url, options);
    return response;
  } catch (error: any) {
    throw error.error;
    //define logging
  }
};

export const uploadPassport = async ({ userId, passport }: any) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth-Token': getAccessToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ passport }),
    };
    const url = `${process.env.REACT_APP_API_URL}/users/${userId}/documents/passport`;
    //@ts-ignore
    const response = await fetch(url, options);

    return response;
  } catch (error: any) {
    throw error.error;
  }
};

export const getUserInfo = async (uid: string | undefined): Promise<any> => {
  try {
    const response = await authHttpClient.get(`users/${uid}`);
    const data = await response.data;
    return data;
  } catch (error: any) {
    throw error.error;
    //define logging
  }
};

export const getTokenOnfido = async (uid: string) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/users/${uid}/onfido`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest ',
        'X-Auth-Token': `${getAccessToken()}`,
      },
    });
    if (!!!response.ok) {
      throw response;
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const getTokenKYC3 = async () => {
  try {
    const response = await kyc3Axios.post('/kyc/on-boarding', {
      token: `${getAccessToken()}`,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadLicence = async ({ userId, ...payload }: any) => {
  const url = `/users/${userId}/documents/licence`;
  const response = await authHttpClient.post(url, payload);
  return response;
};

export const getCusInfoByPartnerIdAndCusId = async (
  partnerId: string | undefined,
  cusId: string | undefined,
): Promise<CustomerInfoCP | undefined> => {
  try {
    const response = await internalAuthHttpClient.get(`/partner/${partnerId}/user/${cusId}`);
    return await response.data.data;
  } catch (error) {
    window.location.href = '/404';
  }
};

export const getCusInfoByToken = async (token: string | null): Promise<CustomerInfoCP | undefined | null> => {
  try {
    const response = await authHttpClient.post('/checkin/verify', { token: token });
    return await response.data;
  } catch (error) {
    return null;
    // window.location.href = '/404';
  }
};

export const getCustomerInfoCP = async (cusId: string | undefined): Promise<CustomerInfoDetailCP | undefined> => {
  try {
    const response = await authHttpClient.get(`/users/partner/${cusId}`);
    return await response.data.data;
  } catch (error: any) {
    throw error.error;
  }
};

export const checkPhoneNumber = async (phoneNumber: string | undefined): Promise<any> => {
  try {
    const response = await httpClient.get(`/users?phone_number=${phoneNumber}`);
    const data = await response.data;
    return data;
  } catch (error: any) {
    throw error.error;
  }
};

export const updatePrivacy = async (
  cusId: string | undefined,
  payload: any,
): Promise<CustomerInfoDetailCP | undefined> => {
  try {
    const response = await authHttpClient.patch(`/users/${cusId}/checkin`, payload);
    const data = await response.data;
    return data;
  } catch (error: any) {
    throw error.error;
  }
};

export const verifyAdditionalDriver = async (userId: string, orderId: string) => {
  const response = await authHttpClient.post(`/users/${userId}/orders/${orderId}/additional-drivers`);
  return response.data;
};
