import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

export const Wrapper = styled('div', { name: 'HeaderWrapper' })(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  position: 'relative',
  padding: '26px 24px',
  width: '100%',
  borderBottom: '1px solid #262626',
  [theme.breakpoints.up('md')]: {
    padding: '34px 64px',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '40px 64px',
  },
  [theme.breakpoints.up('xl')]: {
    padding: '40px 96px',
  },
}));
export const Footer = styled('div', { name: 'FooterWrapper' })(({ theme }) => ({
  height: 'auto',
  position: 'relative',
  padding: '24px 24px',
  width: '100%',
  borderTop: '1px solid #262626',
  [theme.breakpoints.up('md')]: {
    padding: '40px 64px',
  },
  [theme.breakpoints.up('xl')]: {
    padding: '48px 96px',
  },
}));

export const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  bottom: '0',
  left: 0,
  height: 3,
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: '#D393E0',
  },
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#292929',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

export const ContentWrapper = styled('div', { name: 'ContentWrapper' })(({ theme }) => ({
  height: '100%',
  position: 'relative',
  marginTop: '48px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingLeft: 10,
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

export const StepWrapper = styled('div')(({ theme }) => ({}));

export const RadioWrapper = styled('div', {
  shouldForwardProp: (propName: string) => propName !== 'disable',
})<{ disable: boolean }>(({ theme, disable }) => ({
  translate: 'calc(-50% + 0.5px)',
  width: '24px',
  height: '24px',
  zIndex: '2',
  backgroundColor: '#090909',
  padding: '2px',
}));

export const Radio = styled('img', {
  shouldForwardProp: (propName: string) => propName !== 'disabled',
})<{ disable: boolean }>(({ theme, disabled }) => ({
  width: '20px',
  height: '20px',
  ...(disabled && {
    opacity: '0.2',
  }),
}));

export const StepText = styled('div')(({ theme }) => ({
  paddingLeft: '6px',
  fontSize: '16px',
  lineHeight: '1.5',
  letterSpacing: '1px',
  fontFamily: 'FuturaLTPro-Book',
}));

export const VerticalDash = styled('div')(({ theme }) => ({
  height: '100%',
  borderLeft: 'solid 1px #7c7c7c',
  opacity: '0.2',
  position: 'absolute',
  zIndex: 1,
}));

export const Step = styled('div', {
  shouldForwardProp: (propName: string) => propName !== 'disable',
})<{ disable: boolean }>(({ theme, disable }) => ({
  marginBottom: '20px',
  alignText: 'center',
  display: 'flex',
  ...(disable
    ? {
        color: '#7c7c7c',
      }
    : {
        color: '#fff',
      }),
}));
