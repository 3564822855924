import { signIn } from '@/api/auth';
import { Button, StyledForm, Text } from '@/components/common';
import { FormMarginDense, PasswordField, TextField } from '@/components/form';
import { firebaseLogin } from '@/libs/firebase';
import { useAppDispatch } from '@/redux/hooks';
import { CONTACT_EMAIL, ERROR_CODES } from '@/utils/constant';
import { EmailSchema, PasswordSchema } from '@/utils/validation';
import { Box } from '@mui/material';
import { Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ErrorMessageWrapper, ErrorWrapper, ModalHeader } from './styled';

const schema = yup.object().shape({
  email: EmailSchema,
  password: PasswordSchema,
});

const SigninModal: React.FC<any> = ({ closeModal, handleAfterAuthSuccess }) => {
  const formikRef = useRef(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>('');

  const onClose = () => {
    closeModal();
  };

  const onLogin = async (email: string, password: string) => {
    try {
      dispatch({ type: 'SHOW_LOADING' });
      await firebaseLogin(email, password);
      const authInfo = await signIn({ email, password });
      if (authInfo?.userId && authInfo?.accessToken && authInfo?.refreshToken) {
        await handleAfterAuthSuccess(authInfo?.userId, authInfo.accessToken, authInfo?.refreshToken, null, true);
      }
    } catch (error: any) {
      dispatch({ type: 'HIDE_LOADING' });
      if (
        error.code === ERROR_CODES.USER_NOT_FOUND ||
        error.code === ERROR_CODES.INVALID_PASSWORD ||
        error.code === ERROR_CODES.USER_DISABLE ||
        error.code === ERROR_CODES.USER_DISABLED
      ) {
        setError(t('ERROR_MESSAGE_VALID_CREDENTIAL'));
      } else {
        setError(t('ERROR_MESSAGE_SOMETHING_WENT_WRONG'));
      }
    }
  };

  const onSubmit = async (values: any) => {
    await onLogin(values.email, values.password);
  };

  return (
    <>
      <ModalHeader color="primary" variant="title" size="21" sx={{ lineHeight: '28px' }}>
        {t('SIGN_IN')}
        <img
          style={{ width: '20px', height: '20px', cursor: 'pointer' }}
          src="/images/icon/ico_close_x.svg"
          onClick={onClose}
          alt=""
        />
      </ModalHeader>
      {error ? (
        <>
          <ErrorWrapper>
            <img src={`/images/icon/Icon_Warning.svg`} alt="" width={16} height={16} />
            <ErrorMessageWrapper>
              <Trans
                i18nKey={error}
                components={{
                  strong: (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a href={CONTACT_EMAIL} />
                  ),
                }}
              />
            </ErrorMessageWrapper>
          </ErrorWrapper>
        </>
      ) : null}

      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({ values, isValid }) => (
          <StyledForm>
            <FormMarginDense value={24}>
              <TextField
                type="email"
                name="email"
                config={{
                  variant: 'standard',
                  label: t('EMAIL_ADDRESS'),
                  themeColor: 'white',
                  onClick: () => setError(null),
                  InputProps: {
                    'data-testid': 'test_email',
                  },
                }}
              />
            </FormMarginDense>
            {/* Password */}
            <FormMarginDense value={24}>
              <PasswordField
                name="password"
                config={{
                  variant: 'standard',
                  label: t('PASSWORD'),
                  themeColor: 'white',
                  onClick: () => setError(null),
                  InputProps: {
                    'data-testid': 'test_password',
                  },
                }}
              />
            </FormMarginDense>
            <Box sx={{ mt: 2 }}>
              <Text color="secondary" variant="content" size="14">
                <Trans
                  i18nKey={'CONTACT_FOR_RESET_PASS'}
                  components={{
                    strong: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a href={CONTACT_EMAIL} />
                    ),
                  }}
                />
              </Text>
            </Box>
            <FormMarginDense value={24}>
              <Button
                fullWidth
                variant="contained"
                color="dark"
                type="submit"
                disabled={!(isValid && values.email && values.password)}
                sx={{
                  textTransform: 'none',
                  boxShadow: 'unset',
                  '&:hover': {
                    boxShadow: 'unset',
                  },
                }}
              >
                {t('SIGN_IN')}
              </Button>
            </FormMarginDense>
          </StyledForm>
        )}
      </Formik>
    </>
  );
};

export default SigninModal;
