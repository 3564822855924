//@ts-nocheck
import { forwardRef, useImperativeHandle, useRef } from 'react';

const iframeStyles = {
  base: {
    backgroundColor: '#fff',
    color: '#000',
    fontFamily: 'Satoshi Variable',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '1.4375em',
    '::placeholder': {
      fontFamily: 'Satoshi Variable',
      color: '#fff',
    },
    ':-webkit-autofill': {
      color: '#000',
    },
  },
  invalid: {
    iconColor: '#eb1c26',
    color: '#eb1c26',
  },
  complete: {
    iconColor: '#cbf4c9',
  },
};

const StripeInputLight: React.FC<any> = forwardRef((props, inputRef) => {
  const { component: Component, ...other } = props;
  const elementRef = useRef(null);
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef?.current?.focus,
  }));

  return (
    <Component
      onReady={(element: any) => (elementRef.current = element)}
      {...other}
      options={{ style: iframeStyles }}
    />
  );
});

export default StripeInputLight;
