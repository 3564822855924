import { useAppSelector } from '@/redux/hooks';
import { LOGO_NAME, LOGO_WIDTH, THEME } from '@/utils/constant';
import { FC, useMemo } from 'react';
import TheOutLogo from '../TheOutLogo';

type Props = {
  theme?: string;
};

const Logo: FC<Props> = ({ theme = 'dark' }) => {
  const partnerCP = useAppSelector((state: any) => state.user.partner);

  const logoName = useMemo(() => {
    // remove Quintessentially logo on task CP-719
    // const isExistImgUrl = UrlExists(`https://static.theout.com/checkin/logo/${partnerCP}.svg`);
    // if (isExistImgUrl) return partnerCP;
    return LOGO_NAME.THEOUT;
  }, [partnerCP]);

  const width = useMemo(() => {
    if (logoName === LOGO_NAME.THEOUT && theme === THEME.DARK) {
      return LOGO_WIDTH.theout.dark;
    }
    if (logoName === LOGO_NAME.THEOUT && theme === THEME.LIGHT) {
      return LOGO_WIDTH.theout.light;
    }
    if (logoName === LOGO_NAME.QUINTESSENTIALlY && theme === THEME.DARK) {
      return LOGO_WIDTH.quintessentially.dark;
    }
    if (logoName === LOGO_NAME.QUINTESSENTIALlY && theme === THEME.LIGHT) {
      return LOGO_WIDTH.quintessentially.light;
    }
    return LOGO_WIDTH.default;
  }, [logoName]);
  return <TheOutLogo width={width} theme={theme} logo={logoName} />;
};
export default Logo;
