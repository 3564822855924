import { styled } from '@mui/material';

export const Label = styled('div', {
  shouldForwardProp: (propName: string) => propName !== 'themeColor',
})<{ themeColor: string }>(({ theme, themeColor = 'dark' }) => ({
  fontSize: '14px',
  lineHeight: '16px',
  fontFamily: 'Satoshi Variable',
  color: themeColor === 'white' ? '#555' : '#999',
  transform: 'translate(0,-1.5px) scale(1) !important',
  [theme.breakpoints.up('sm')]: {
    lineHeight: '16px',
  },
}));

const FormLabel = ({ text, themeColor }: { text: string; themeColor?: string }) => {
  return <Label themeColor={themeColor}>{text}</Label>;
};
export default FormLabel;
