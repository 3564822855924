import { FormMarginDense, TextField } from '@/components/form';
import StripeTextField from '@/components/form/StripeTextField';
import { getCardLogoByType } from '@/utils/cards';
import { InputAdornment } from '@mui/material';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CardIcon, CardSecurity, FormMarginRightDense, PaymentFormWrapper, PostCodeWrapper } from './styled';

const PaymentForm: React.FC<any> = ({ StripeInput, themeColor = 'dark', setCompleted, marginRight, mode = 'page' }) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    cardNumberComplete: false,
    cardNumberError: '',
    cardNumberEmpty: true,
    cvcComplete: false,
    cvcError: '',
    cvcEmpty: true,
    expiredComplete: false,
    expiredError: '',
    expiredEmpty: true,
    cardType: null,
  });

  const onElementChange =
    (field: any, errorField: any, emptyField?: any) =>
    ({ complete = null, error = { message: null }, brand = null, empty = true }) => {
      if (field === 'cardNumberComplete') {
        setState({ ...state, [field]: complete, [errorField]: error.message, cardType: brand, [emptyField]: empty });
      } else {
        setState({ ...state, [field]: complete, [errorField]: error.message, [emptyField]: empty });
      }
    };
  const { cardNumberError, expiredError, cvcError, cardNumberComplete, expiredComplete, cvcComplete, cardType } = state;

  const onElementBlur = (field: string, errorField: string, errorText: string, id: string) => () => {
    const elementContainer = document.querySelector(`#${id}`);
    const elementEmpty = elementContainer?.classList.contains('StripeElement--empty');
    const elementComplete = elementContainer?.classList.contains('StripeElement--complete');
    if (elementEmpty) {
      setState((currentState) => {
        return { ...currentState, [errorField]: `${errorText} is required.` };
      });
    } else {
      // check complete when autofill safari
      setState((currentState) => {
        return { ...currentState, [field]: elementComplete };
      });
    }
  };
  useEffect(() => {
    const isComplete = cardNumberComplete && expiredComplete && cvcComplete;
    setCompleted(isComplete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardNumberComplete, expiredComplete, cvcComplete]);

  return (
    <PaymentFormWrapper>
      <FormMarginDense value="24" mode={mode} marginTop={false}>
        <StripeTextField
          id="cardNumber"
          data-testid="cardNumber"
          label={t('DEPOSIT_CARD_NUMBER')}
          name="cardNumber"
          variant="standard"
          themeColor={themeColor}
          required
          fullWidth
          autocomplete="off"
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardNumberElement,
            },
            endAdornment: (
              <InputAdornment position="end">
                {cardType && cardType !== 'unknown' ? (
                  <CardIcon src={getCardLogoByType(cardType, true)} width={26} height={16} />
                ) : (
                  <>
                    <CardIcon fetchPriority={'high'} src="/images/cards/Payment_Visa.svg" width={26} height={16} />
                    <CardIcon fetchPriority={'high'} src="/images/cards/Payment_Amex.svg" width={26} height={16} />
                    <CardIcon
                      fetchPriority={'high'}
                      src="/images/cards/Payment_Mastercard.svg"
                      width={26}
                      height={16}
                    />
                  </>
                )}
              </InputAdornment>
            ),
          }}
          helperText={cardNumberError}
          InputLabelProps={{ shrink: true }}
          error={Boolean(cardNumberError)}
          onChange={onElementChange('cardNumberComplete', 'cardNumberError', 'cardNumberEmpty')}
          onBlur={onElementBlur('cardNumberComplete', 'cardNumberError', 'Card Number', 'cardNumber')}
        />
      </FormMarginDense>
      <FormMarginDense value="32" mode={mode} marginTop={true}>
        <TextField
          name="cardName"
          config={{
            label: t('DEPOSIT_CARD_NAME'),
            themeColor: themeColor,
            toUpperCase: true,
            size: 16,
          }}
        />
      </FormMarginDense>
      <FormMarginDense value="32" mode={mode} marginTop={false}>
        <CardSecurity mode={mode}>
          <FormMarginRightDense value={marginRight} mode={mode} marginTop={true}>
            <StripeTextField
              id="expiryDate"
              data-testid="expiryDate"
              label={t('DEPOSIT_EXPIRED_DATE')}
              name="expiryDate"
              variant="standard"
              themeColor={themeColor}
              autocomplete="off"
              required
              fullWidth
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardExpiryElement,
                },
              }}
              error={Boolean(expiredError)}
              helperText={expiredError}
              onChange={onElementChange('expiredComplete', 'expiredError', 'expiredEmpty')}
              onBlur={onElementBlur('expiredComplete', 'expiredError', 'Expiry Date', 'expiryDate')}
            />
          </FormMarginRightDense>
          <FormMarginRightDense value={marginRight} mode={mode} marginTop={true}>
            <StripeTextField
              label={t('DEPOSIT_CARD_CVC')}
              id="securityCode"
              data-testid="securityCode"
              name="securityCode"
              variant="standard"
              autocomplete="off"
              themeColor={themeColor}
              required
              fullWidth
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardCvcElement,
                },
              }}
              error={Boolean(cvcError)}
              helperText={cvcError}
              onChange={onElementChange('cvcComplete', 'cvcError', 'cvcEmpty')}
              onBlur={onElementBlur('cvcComplete', 'cvcError', 'Security Code', 'securityCode')}
            />
          </FormMarginRightDense>
          <PostCodeWrapper mode={mode} marginTop={true}>
            <TextField
              name="postCode"
              config={{
                label: t('DEPOSIT_CARD_POSTCODE'),
                themeColor: themeColor,
                toUpperCase: true,
                size: 16,
              }}
            />
          </PostCodeWrapper>
        </CardSecurity>
      </FormMarginDense>
    </PaymentFormWrapper>
  );
};
export default PaymentForm;
