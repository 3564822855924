import { styled } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import { useField } from 'formik';
import { isString } from 'lodash';
import * as React from 'react';
import { ErrorMessage } from '../ErrorMessage';
import FormLabel from '../Label';
import { StyledTextField } from '../TextField';
import { convertStringToDate } from './dateFunction';

export const Icon = styled('img')(({ theme }) => ({
  cursor: 'pointer',
}));

const DatePickerField = ({ config, name }: any) => {
  const [field, meta, { setValue, setTouched }] = useField(name ?? 'TextField');
  const [focus, setFocus] = React.useState<boolean>(false);
  const [date, setDate] = React.useState<string>(
    dayjs(field.value, 'YYYY-MM-DD').isValid() ? dayjs(field.value, 'YYYY-MM-DD').format('DD/MM/YYYY') : '',
  );
  const {
    label,
    disabled,
    autoComplete = 'false',
    placeholder,
    variant = 'standard',
    margin,
    fullWidth = true,
    rows,
    size,
    required,
    autoFocus,
    readOnly,
    maxLength,
    InputProps,
    inputProps,
  } = config;

  if (!field.value) {
    field.value = config.defaultValue ?? '';
  }

  const handleChange = (evt: any) => {
    let value = evt.target.value;
    if (isString(date) && evt.target.value.length >= date.length) {
      value = convertStringToDate(value);
    }
    if (dayjs(value, 'DD/MM/YYYY').isValid()) {
      value = dayjs(value, 'DD/MM/YYYY').format('DD/MM/YYYY');
    }
    setDate(value);
  };

  React.useEffect(() => {
    setValue(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const haveError: boolean = !!(meta.error && meta.touched);

  const props = {
    disabled: disabled,
    required,
    autoFocus,
    variant,
    size,
    label,
    inputProps: {
      maxLength,
      autoComplete,
      readOnly,
      ...inputProps,
    },
    InputLabelProps: {
      shrink: true,
    },
    InputProps,
    rows,
    placeholder,
    margin,
    error: haveError && focus,
    fullWidth,
  };
  return (
    <>
      {variant === 'outlined' ? null : <FormLabel text={label} />}
      <DesktopDatePicker
        onChange={function (value: unknown, keyboardInputValue?: string | undefined): void {
          throw new Error('Function not implemented.');
        }}
        inputFormat="DD/MM/YYYY"
        {...props}
        value={dayjs(date).format('DD/MM/YYYY')}
        renderInput={(params: any) => {
          return (
            <>
              <StyledTextField
                value={date}
                label={label}
                inputProps={{
                  placeholder: 'DD / MM / YYYY',
                  ...props.inputProps,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e: any) => {
                  field.onBlur(e);
                  setTouched(true);
                  setFocus(false);
                }}
                onClick={() => setFocus(true)}
                onChange={handleChange}
                error={haveError && !focus}
                variant={variant}
                fullWidth
              />
              {!focus && meta.error && meta.touched ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
            </>
          );
        }}
      />
    </>
  );
};

export default DatePickerField;
