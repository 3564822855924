import { InputAdornment, styled } from '@mui/material';
import { useField } from 'formik';
import FormLabel from '../Label';
import * as React from 'react';
import { StyledTextField } from '../TextField';
import { ErrorMessage } from '../ErrorMessage';

export const Icon = styled('img')(({ theme }) => ({
  cursor: 'pointer',
}));

const PasswordField = ({ config, name, formik }: any) => {
  const [field, meta] = useField(name ?? 'TextField');
  const [focus, setFocus] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const {
    label,
    disabled,
    autoComplete = 'false',
    placeholder,
    themeColor,
    variant = 'standard',
    margin,
    fullWidth = true,
    rows,
    size,
    required,
    autoFocus,
    readOnly,
    maxLength,
    onClick,
    InputProps,
  } = config;

  // fix: A component is changing an uncontrolled input
  if (!field.value) {
    field.value = config.defaultValue ?? '';
  }

  const haveError: boolean = meta.touched && Boolean(meta.error);
  const onBlur = (e: any) => {
    field.onBlur(e);
    setFocus(false);
  };
  const onFocus = (e: any) => {
    setFocus(true);
    onClick();
  };
  const props = {
    ...field,
    onBlur,
    label: variant === 'outlined' ? label : null,
    onClick: onFocus,
    disabled: disabled,
    required,
    autoFocus,
    variant,
    themeColor,
    size,
    inputProps: {
      maxLength,
      autoComplete,
      readOnly,
    },
    InputLabelProps: {
      shrink: true,
    },
    rows,
    placeholder,
    margin,
    error: haveError && !focus,
    fullWidth,
  };

  return (
    <>
      {variant === 'outlined' ? null : <FormLabel text={label} />}
      <StyledTextField
        {...props}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          ...InputProps,
          endAdornment: (
            <InputAdornment position="end">
              <div aria-label="toggle password visibility" onClick={() => setShowPassword((prev) => !prev)}>
                {showPassword ? (
                  <Icon src="/images/icon/ico_close_eye.svg" />
                ) : (
                  <Icon src="/images/icon/ico_open_eye.svg" />
                )}
              </div>
            </InputAdornment>
          ),
        }}
      />
      {!focus && meta.error && meta.touched ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
    </>
  );
};

export default PasswordField;
